<template>
  <div class="flex h-screen justify-center items-center bg-gray-50"> 
    <div class="xl:w-11/12 w-full rounded-3xl">
      <p class="xl:text-6xl xl:pl-20 xl:pr-20 pr-10 pl-10 text-2xl font-mono text-gray-500 pt-28">Hi, I'm Roshen Maghhan</p>
      <p class="xl:text-2xl xl:pl-20 xl:pr-20 pr-10 pl-10 text-1xl font-mono text-gray-400 pt-10 ">A full stack dev, who began coding at the age of 14. I enjoy problem solving, & would usually work on the backend aspect of projects. Currently, I work on developing open-sourced government projects. Feel free to read more about my <a href="#industry_projects" class="underline text-gray-500">projects</a>, and my <a href="#hobbies" class="underline text-gray-500">hobbies</a>! 
      </p>

      <div class="xl:flex md:flex xl:mt-20 mt-10 pb-28">
        <div class="xl:w-9/12 md:w-9/12 w-full">
          <p class="font-mono text-1xl xl:pl-20 pl-10 text-gray-500">📍 Selangor, Malaysia</p>
          <p class="font-mono text-1xl xl:pl-20 pl-10 pt-3 text-gray-500">🏢 Ministry of Finance</p>
          <p class="font-mono text-1xl xl:pl-20 pl-10 pt-3 text-gray-500">💼 Lead Backend Engineer</p>
        </div>
        <div class="flex items-end w-full justify-center xl:mt-14 md:mt-14 mt-20">
          <a href="https://twitter.com/roshennn" target="_blank">
            <img src="./assets/socials/twitter.jpg" class="w-10 h-10 mr-7 cursor-pointer">
          </a>
          <a href="https://www.linkedin.com/in/roshen-maghhan-831b3498/" target="_blank">
            <img src="./assets/socials/linkedin.png" class="w-11 h-11 mr-7 cursor-pointer">
          </a>          
          <a href="https://www.instagram.com/roshenmaghhan/?hl=en" target="_blank">
            <img src="./assets/socials/instagram.png" class="w-10 h-10 mr-7 cursor-pointer">
          </a>
          <a href="https://github.com/roshenmaghhan" target="_blank">
            <img src="./assets/socials/github.png" class="w-11 h-11 mr-7 cursor-pointer">
          </a>
          <a href = "mailto: roshenmaghhan@gmail.com">
            <img src="./assets/socials/gmail.png" class="w-11 h-11 cursor-pointer">
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-50 w-full flex justify-center"> <!-- Change to border-gray-50 -->
    <div class="xl:w-11/12 xl:pl-20 xl:pr-20 pl-7 pr-7 mt-10 mb-10">
      <p class="xl:text-5xl xl:pb-10 text-4xl font-mono text-gray-500" id="industry_projects">Projects</p>

      <div class="grid grid-cols-4 gap-4 mt-10 mb-10 pb-10" v-for="project in projects" key="project.title">
        <div class="xl:col-span-3 col-span-4 border-gray-900 rounded-lg drop-shadow-lg">
          <img class="xl:h-full xl:w-auto xl:pr-0 xl:pl-0 md:pr-10 md:pl-10 rounded-lg" :src="require('@/assets/projects/' + project.image)" />
        </div>
        <div class="xl:col-span-1 col-span-4 border-gray-900 rounded-lg drop-shadow-lg flex justify-center items-center">
          <div class="xl:pl-10 xl:mt-0 md:pl-3 md:pr-3 mt-7">
            <p class="font-mono lg:text-3xl text-2xl text-gray-500">{{ project.title }}</p>
            <p class="font-mono text-gray-400 pt-7 text-1xl">{{ project.description }}</p>
            <p class="font-mono text-gray-400 pt-7">📅 {{ project.timeline }}</p>
            <p class="font-mono text-gray-400 pt-5">📚 {{ project.stack }}</p>
            <p class="font-mono text-gray-400 pt-5">💻 {{ project.position }} </p>
            <a :href="project.link" target="_blank" v-if="project.link">
              <p class="font-mono text-gray-400 mt-7 pt-3 drop-shadow-md pb-3 cursor-pointer border-gray-400 border-solid border-2 text-center rounded-lg" >View {{ project.title }}</p>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="bg-gray-50 w-full flex justify-center pb-10">
    <div class="xl:w-11/12 md:w-11/12 xl:pl-20 xl:pr-20 pl-6 pr-6 w-full mt-10 mb-10">
      <p class="font-mono xl:text-6xl text-4xl text-gray-500 xl:text-left text-center" id="hobbies">More About me</p>
      <p class="font-mono xl:text-2xl text-1xl text-gray-400 pt-10 xl:pr-10">In my spare time, I enjoy building and innovating my personal projects. Some of which have caught the attention of the local media. Below are some of my personal projects & hobbies : </p>
      
      <div class="grid grid-cols-4 gap-6 mt-10 2xl:w-11/12 md:w-11/12 w-full block m-auto">
        <div v-for="personal in personal_projects" key="personal.title" class="xl:col-span-2 md:col-span-2 col-span-4 border-gray-50 border-solid border-2 bg-gray-50 rounded-lg drop-shadow-lg">
          <p class="text-gray-500 xl:text-3xl text-2xl font-mono pt-10 pl-10 pr-10">{{ personal.title }}</p>
          <p class="text-gray-500 text-1xl font-mono pt-5 pl-10 pr-10">{{ personal.description }}</p>
          <a :href="personal.link" target="_blank">
            <p class="w-3/4 font-mono text-gray-400 mt-7 pt-3 drop-shadow-md pb-3 cursor-pointer border-gray-400 border-solid border-2 block m-auto text-center rounded-lg mb-10">Read About it</p>
          </a>
        </div>           
      </div>

      <div class="col-span-4 mt-20">
        <p class="xl:text-4xl text-2xl text-gray-500 font-mono text-center">Catch me on the news!</p>
        
        <div class="grid grid-cols-4 mt-10 gap-10 2xl:w-3/4 w-10/12 block m-auto">
          <div class="xl:col-span-1 md:col-span-1 col-span-2 flex items-center justify-center" v-for="news in news_outlets" key="news.link">
            <a :href="news.link" target="_blank">
              <img :src="require('@/assets/news/' + news.image)" class="pr-3 pl-3 cursor-pointer">
            </a>
          </div>                                                                                          
        </div>
      </div>
    </div>
  </div>

  <div class="w-full bg-gray-50">
      <p class="text-center pb-20 font-mono text-gray-500 text-1xl">© {{ new Date().getFullYear() }} Roshen Maghhan. All rights reserved.</p>
  </div>

</template>

<script>
export default {
  data() {
    return {
      "projects": [
        {"link" : "https://open.dosm.gov.my/", "image" : "opendosm.png", "title" : "OpenDOSM", "description" : "Was responsible for all back-end related matters, including back-end architecture, data refresh and update automation, as well as DevOps", "timeline" : "Nov 2022 - Jan 2023", "stack" : "Next.js, Django", "position" : "Lead Backend Engineer"},
        {"link" : "https://data.moh.gov.my/", "image" : "kkmnow.png", "title" : "KKMNOW", "description" : "Was responsible for all back-end related matters, including architecture, database management, API handling, process flow automation, and DevOps", "timeline" : "Sep 2022 - Oct 2022", "stack" : "Next.js, Django", "position" : "Lead Backend Engineer"},
        {"link" : "https://covidnow.moh.gov.my/", "image" : "covidnow.png", "title" : "COVIDNOW", "description" : "Developed pro-bono for the Malaysian government. Was responsible for backend-related matters pertaining algorithms for scales and charts, and API handling.", "timeline" : "Aug 2021 - Sep 2021", "stack" : "Next.js, Django", "position" : "Backend Engineer"},
        {"link" : null, "image" : "ekonomy.png", "title" : "EkonoMY", "description" : "Developed a full-fledged survey engine, frontend & backend, with support of different input types, and capable of exporting surveys to an audience", "timeline" : "Jan 2021 - Jun 2022", "stack" : "Vanilla Js, PHP", "position" : "Full Stack Engineer"},
      ],
      "personal_projects" : [
        {"link": "https://worldofbuzz.com/21yo-genius-can-control-his-myvi-from-his-phone-and-he-used-only-rm200-to-do-it/", "title" : "🏎️ Myvi-Tesla", "description" : "Using an Arduino + other components, turned a local Malaysian car ( Myvi ) into somewhat of a Tesla, by tweaking units of the car, allowing it to power the car on and off, rolling the windows down, playing music, and even moving the car forward, all through the convinience of my smart phone."},
        {"link": "https://says.com/my/tech/developer-gets-tired-of-colleagues-speaking-in-mandarin-and-makes-real-time-translator", "title" : "🎧 Pixelbuds", "description" : "Tired of hearing my ex-colleagues speak Mandarin during meetings ( as I'm not a Mandarin speaker ), I replicated the Google Pixel Buds by using the Google Translate API combined with my own text-to-speech algorithm, to provide a better accuracy in account for the Malaysian slang."},
        {"link": "https://twitter.com/roshennn/status/1088456385686253569", "title" : "🏡 DIY Smart home", "description" : "To further enhance my hardware skills, using an Arduino + IR read/emit sensors + voice recognition software, developed a prototype Google Home, capable of controlling my IR-powered house appliances, such as the fan, TV, and speakers."},
        {"link": "https://www.hackerrank.com/leaderboard?filter=Malaysia&filter_on=country&page=1&track=algorithms&type=practice", "title" : "🏆 Programming", "description" : "To sharpen my programming skills, I ventured into competitive programming and attained a 6-star problem solving badge ( gold ), and was ranked 3rd in my country on Hackerrank ( Oct 2020 ), and 1st in my country on Dcoder ( Feb 2021 )."}
      ],
      "news_outlets" : [
        {"link" : "https://www.thestar.com.my/news/nation/2022/05/30/big-dreams-in-the-tech-sphere", "image" : "star.png"},
        {"link" : "https://www.tatlerasia.com/power-purpose/ideas-education/taylors-university-covidnow-cultiv8-arrivo", "image" : "tatler.png"},
        {"link" : "https://says.com/my/tech/developer-gets-tired-of-colleagues-speaking-in-mandarin-and-makes-real-time-translator", "image" : "says.png"},
        {"link" : "https://worldofbuzz.com/21yo-genius-can-control-his-myvi-from-his-phone-and-he-used-only-rm200-to-do-it/", "image" : "wob.png"},
        {"link" : "https://www.ted.com/talks/roshen_maghhan_a_digital_approach_to_innovation_and_problem_solving", "image" : "ted.png"},
        {"link" : "https://www.therakyatpost.com/fun/2019/07/11/msian-couldnt-understand-work-meetings-mandarin-made-diy-real-time-translator/", "image" : "trp.png"},
        {"link" : "https://hitz.syok.my/trending/trending-on-hitz/msian-guy-made-a-real-time-translator-to-understan", "image" : "syok.png"},
        {"link" : "https://www.pressreader.com/malaysia/augustman-malaysia/20200801/282784948815395", "image" : "augustman.png"},
        {"link" : "https://open.spotify.com/episode/3FRTWeMbW2o0fuw6AWRllM", "image" : "spotify.png"},
        {"link" : "https://www.nst.com.my/news/nation/2021/09/726457/heroes-lift-covid-veil-secrecy", "image" : "nst.png"},
        {"link" : "https://sea.mashable.com/tech/5016/malaysian-creates-language-translator-because-colleagues-spoke-in-mandarin-during-work-meetings", "image" : "mashable.png"},
        {"link" : "https://paultan.org/2019/09/17/myvi-tesla-projek-baharu-roshen-maghhan/", "image" : "paultan.png"},
      ]
    }
  }
}
</script>
